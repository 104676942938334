import React from 'react';
// Bootstrap
import { Row, Col } from 'react-bootstrap';

// React Router
import { withRouter } from 'react-router-dom'

// Loading overlay
import LoadingOverlay from 'react-loading-overlay';

// EllipsisWithTooltip
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'

// Project imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltUp, faLongArrowAltDown, faSpinner, faAngleLeft, faAngleDown, faLink  } from '@fortawesome/free-solid-svg-icons'

import TweetEmbed from 'react-tweet-embed';
import { FacebookProvider, EmbeddedPost  } from 'react-facebook';

// Assets
import universities from '../../assets/universities.svg';
import hospitals from '../../assets/hospitals.svg';
import associations from '../../assets/associations.svg';
import youtube from '../../assets/youtube.png';
import twitter from '../../assets/x.svg';
import facebook from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.png';

// Animate Height
import AnimateHeight from 'react-animate-height';

// Axios
import axios from 'axios';

// Project Imports
import InspirePagination from '../shared/pagination'
import SearchHeader, { SEARCH_HEADER } from '../shared/searchheader'

const DATA_FIELD_LIST = [
  { 
    dataField:'social_media_name', caption: 'name', 
    label: 'Name', type: SEARCH_HEADER.TEXT
  },
  { 
    dataField:'social_media_tags', caption: 'social_media_tags', 
    label: 'Tags', type: SEARCH_HEADER.TEXT
  },
  { 
    dataField:'social_media_weblink', caption: 'social_media_weblink', 
    label: 'WebLink', type: SEARCH_HEADER.TEXT
  },
  { 
    dataField:'social_media_date', caption: 'social_media_date', 
    label: 'Date', type: SEARCH_HEADER.TEXT
  },
  { 
    dataField:'relationship_type', caption: 'relationship_type', 
    label: 'Relation Type', type: SEARCH_HEADER.TEXT
  }
]

const Affiliation = (props) => {
  return (
    <div className="text-center m-1 h-100 d-flex justify-content-between flex-column" style={{ border: '1px solid #D1E3F2', borderRadius: '5px' }}>
      <div>
        <div className="font-weight-bold" style={{ padding: '1em 2em 1em 2em' }}>
          {props.name}
        </div>
        <div className="font-weight-bold">{props.number}</div>
      </div>
      <div style={{ padding: '1em 2em 1em 2em' }}>
        <img className="w-100" src={props.img} alt=" "></img>
      </div>

      <div style={{ padding: '1em 2em 1em 2em' }}>
        <button className={props.active && !window.mobile ? "w-100 inspire-button no-padding" : "w-100 inspire-ghost-button no-padding"} variant="primary"
          onClick={(e) => props.handler()}>
          Show Results
      </button>
      </div>
    </div>
  )
}

const AffiliationMobile = (props) => {
  return (
    <div className="text-center m-1" style={{ border: '1px solid #D1E3F2', borderRadius: '5px' }}>

      <div className="d-flex w-100 justify-content-center mt-3">
        <div className="mr-3">
          <img style={{ height: '100px'}} src={props.img} alt=" "></img>
        </div>
        <div className="font-weight-bold d-flex justify-content-center flex-column">
          <p>{props.name}</p>
          <p>{props.number}</p>
        </div>

      </div>

      <div style={{ padding: '1em 2em 1em 2em' }}>
        <button className={props.active && !window.mobile ? "w-100 inspire-button no-padding" : "w-100 inspire-ghost-button no-padding"} variant="primary"
          onClick={(e) => props.handler()}>
          Show Results
      </button>
      </div>
    </div>
  )
}


const SOCIAL_MEDIA_TYPE = { YOUTUBE: 'youtube', TWITTER: 'twitter', FACEBOOK: 'facebook', OTHER: 'other'}
class PanelSocialMedia extends React.Component {

  constructor(props) {
    super(props)
    this.dataFieldList = window.mobile?DATA_FIELD_LIST.slice(0,2):DATA_FIELD_LIST;
    const filteringList = DATA_FIELD_LIST.reduce((acc,curr)=> (acc[curr.caption]='',acc),{});    
    this.state = {
      investigatorId: undefined,
      nYoutube: 0,
      nTwitter: 0,
      nFacebook: 0,
      nLinkedin: 0,
      socialMediaType: SOCIAL_MEDIA_TYPE.YOUTUBE,
      currentPage: 1,
      totalPage: 10,
      take: 10, 
      limit: 10,
      isLoading: false,
      dataTable: [],
      filtering : {...filteringList},
      sorting: '',
      showTableSideTitle: '',
      showTableSideModal: false
    }
  }


  retrieveSocialMediaFiltered(key, value){
    
    let { currentPage, filtering } = this.state;
    for(const candidate_item of this.dataFieldList ){
      if( key === candidate_item.caption ){
        filtering[candidate_item.caption] = value
      }
    }
    // Check format on date
    if(filtering.social_media_date){
      try{
        filtering.social_media_date = new Date(Date.parse(filtering.social_media_date))
        filtering.social_media_date = filtering.social_media_date.toISOString().split('T')[0]
      }catch{
        delete filtering.social_media_date
      }
    }
    // Assign to filtering
    this.state.filtering = filtering;

    // Set Timeout
    if ( this.typingTimeout ) clearTimeout(this.typingTimeout);
    const that = this;
    this.typingTimeout = 
      setTimeout(()  => that.retrieveSocialMedia(currentPage), 2000)

  }

  getSocialMediaTypeTitle(socialMediaType){
    if( socialMediaType === SOCIAL_MEDIA_TYPE.YOUTUBE )  return 'YouTube';
    if( socialMediaType === SOCIAL_MEDIA_TYPE.TWITTER )  return 'Twitter';
    if( socialMediaType === SOCIAL_MEDIA_TYPE.FACEBOOK ) return 'Facebook';
    if( socialMediaType === SOCIAL_MEDIA_TYPE.OTHER )    return 'Other';
    return ''
  }

  getInvestigatorId(){
    const { match: { params } } = this.props;
    let investigatorId = params.subid;
    investigatorId = investigatorId.split('-')[investigatorId.split('-').length -1 ]
    return parseInt( investigatorId )    
  }

  async computeSocialMediaLength(){
    this.state.investigatorId = this.getInvestigatorId()

    const token = localStorage.getItem('token')
    const headers = { "Authorization": "jwt " + token }
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/investigator/${this.state.investigatorId}/social-medias/?limit=10000`, { headers })

    // Get List of items
    const socialMediaList = response.data.results;
    this.state.nYoutube = socialMediaList.filter( item => item.social_media_type === SOCIAL_MEDIA_TYPE.YOUTUBE).length;
    this.state.nTwitter = socialMediaList.filter( item => item.social_media_type === SOCIAL_MEDIA_TYPE.TWITTER).length;
    this.state.nFacebook = socialMediaList.filter( item => item.social_media_type === SOCIAL_MEDIA_TYPE.FACEBOOK).length;
    this.state.nOther = socialMediaList.filter( item => item.social_media_type === SOCIAL_MEDIA_TYPE.OTHER).length;
  }

  async retrieveSocialMedia(page = 1){
    this.state.investigatorId = this.getInvestigatorId()
    
    const base_url = `${process.env.REACT_APP_API_URL}/api/investigator/${this.state.investigatorId}/social-medias/`
    this.setState({isLoading: true, dataTable: []})

    const token = localStorage.getItem('token')
    const { socialMediaType, take, limit, filtering, sorting } = this.state;

    // Perform request
    let skip = this.state.take * (page-1);
    let offset = this.state.take * (page-1);
    let urlParams = `limit=${limit}&offset=${offset}&skip=${skip}&take=${take}&social_media_type=${socialMediaType}`

    // Add filtering
    if( filtering !== undefined ){
      for(const item of this.dataFieldList ){
        if( filtering[item.caption] !== '' ){
          urlParams = `${urlParams}&${item.dataField}=${filtering[item.caption]}`;
        }
      }      
    }




    // Add sorting
    if( sorting !== ''){
      urlParams = `${urlParams}&ordering=${sorting}`;
    }

    const url = `${base_url}?${urlParams}`
    const response = await axios.get(url,
      { headers: { "Authorization": "jwt " + token }
    })

    let dataTable = response.data.results
    dataTable.map( x => x.show = false)
    dataTable.map( x => x.enabled = true)
    dataTable.map( x => x.extra = '')

    if( socialMediaType === SOCIAL_MEDIA_TYPE.YOUTUBE){
      const youtube_id_list = []
      for(const item of dataTable){
        const youtube_id = this.extractYoutubeId(item)
        youtube_id_list.push( youtube_id )
      }
        
      
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${youtube_id_list.join()}&key=${process.env.REACT_APP_YOUTUBE_KEY}&part=snippet`
      const responseYoutube = await axios.get(url)
      for(const [idx, item] of dataTable.entries() ){    


        // Checking if properly formatted
        if( youtube_id_list[idx] == null){
          item.extra = { title: null, description: null, thumbnail: null, publishedAt:null }
          continue
        }

        // Find youtube item
        const youtubeItem = responseYoutube.data.items.find( (youtubeItem) => youtubeItem.id === youtube_id_list[idx])

        // Proceed to continue with Youtube
        const zeroPadded = ( input ) => ('0' + input).slice(-2)
        const input = new Date(youtubeItem.snippet.publishedAt)
        const publishedAt = `${input.getFullYear()}-${zeroPadded(input.getMonth()+1)}-${zeroPadded(input.getDate())} ` + 
                              `${zeroPadded(input.getHours())}:${zeroPadded(input.getMinutes())}`
          
        item.extra = { 
          title: youtubeItem.snippet.title, 
          description: youtubeItem.snippet.description,
          thumbnail: youtubeItem.snippet.thumbnails.default.url,
          publishedAt: publishedAt,
        }        
      }
    }


    if(response.data.results.length < take){
      const filteringList = this.dataFieldList.reduce((acc,curr)=> (acc[curr.caption]='',acc),{});    
      const fill = new Array(take - response.data.results.length).fill(filteringList)
      fill.map( x => x.enabled = false)
      dataTable.push(...fill)
    }
    
    // Set State
    const totalPage = Math.ceil(response.data.count / take);      
    this.setState({
      dataTable: dataTable, 
      currentPage: page,
      totalPage: totalPage,
      isLoading: false,
    })
  }


  showTable(socialMediaType, componentDidMount = false){    
    this.state.socialMediaType = socialMediaType;
    this.state.currentPage = 1;
    this.state.totalPage = 1;
    this.state.take = 10;
    this.state.limit = 10;
    this.state.isLoading = false;

    // Mobile
    this.state.showTableSideTitle = this.getSocialMediaTypeTitle()
    this.state.showTableSideModal = true
    // Retrieve Social Media
    this.retrieveSocialMedia() 
  }


  async componentDidMount(){
    await this.computeSocialMediaLength()
    this.retrieveSocialMedia()
  }  

  navigatePage(page) {
    this.retrieveSocialMedia(page)
  }

  onSetSorting(field){
    let { currentPage, sorting } = this.state;
    let target = '';
    if( sorting == '' || sorting.includes(field) == false){
      target = field
    }else if( sorting === field ){
     target =  `-${field}`
    }
    this.state.sorting = target;

    this.retrieveSocialMedia(currentPage)
  }

  extractYoutubeId(item){
    if( item.social_media_weblink && item.social_media_type === SOCIAL_MEDIA_TYPE.YOUTUBE ){      
      const url = new URL( item.social_media_weblink )
      return url.searchParams.get('v')
    } 
    return ''
  }
  extractTwitterId(item){
    if( item.social_media_weblink && item.social_media_type === SOCIAL_MEDIA_TYPE.TWITTER ){
      return item.social_media_weblink.split('/')[item.social_media_weblink.split('/').length -1]
    } 
    return ''
  }

  renderTableDesktop(){
    const { dataTable } = this.state;
    const { currentPage, totalPage, sorting } = this.state;
    return (<>
      <table className="w-100 inspire-table" style={{ tableLayout: 'fixed'}}>
        <thead>
          <tr>
            <td>Social Media Type</td>
            {this.dataFieldList.map((item, id) =>
            <td key={id} style={{ cursor: 'pointer' }}
              onClick={(e) => this.onSetSorting(item.dataField)}>
              {item.label}
              <FontAwesomeIcon icon={faLongArrowAltUp} className={sorting == item.dataField ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
              <FontAwesomeIcon icon={faLongArrowAltDown} className={sorting == `-${item.dataField}` ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
            </td>
            )}
          </tr>
          <tr style={{ border: '1px solid #A4C8E6', borderWidth: '1px 0px 2px 0px' }}>
            <td></td>
            {this.dataFieldList.map((item, id) =>
              <td key={id} className="text-center">
                <SearchHeader 
                  onChange={(pattern) => this.retrieveSocialMediaFiltered(item.caption, pattern)} 
                  type={item.type} />
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {this.state.isLoading?
            <>
            <tr>
              <td></td>
              <td rowSpan="10" style={{ background: 'white', height: '400px' }} colSpan="14" className="text-center">
                <div className="mb-3" style={{ fontSize: '20px', color: 'grey' }} >Loading ...</div>
                <FontAwesomeIcon icon={faSpinner}  spin style={{ fontSize: '40px', color: 'grey' }} />                    
              </td>
            </tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            </>
            :<tr></tr>}

          {dataTable.map((item, id) =>
            <React.Fragment key={id}>
            <tr>
                <td style={{ width: '10%'}}>  
                  {this.getSocialMediaTypeTitle(item.social_media_type)}
                  {/* {item.social_media_weblink && item.social_media_type===SOCIAL_MEDIA_TYPE.YOUTUBE?
                    <a href={item.social_media_weblink} target="_blank">
                      <img className="w-100" src={`https://img.youtube.com/vi/${this.extractYoutubeId(item)}/0.jpg`}></img>
                    </a>
                  :''}
                  {item.social_media_type!==SOCIAL_MEDIA_TYPE.YOUTUBE?this.getSocialMediaTypeTitle(item.social_media_type):''} */}
                  {/* 
                  {item.social_media_type===SOCIAL_MEDIA_TYPE.TWITTER?
                    <div className="text-center" style={{ maxWidth: '100px', overflow: 'auto', maxWidth: '200px', maxHeight: '200px'}}>
                    <TweetEmbed className="w-100" id={this.extractTwitterId(item)} />
                    </div>
                  :''}
                  {item.social_media_type===SOCIAL_MEDIA_TYPE.FACEBOOK?this.getSocialMediaTypeTitle(item.social_media_type):''}
                  {item.social_media_type===SOCIAL_MEDIA_TYPE.OTHER?
                    <div className="text-center" style={{ maxWidth: '100px', overflow: 'auto', maxWidth: '200px', maxHeight: '200px'}}>
                      <FacebookProvider appId="564870858293382">
                        <EmbeddedPost  href="https://www.facebook.com/sergi.torrellas/posts/10158380783357113" width="20" />
                      </FacebookProvider>
                    </div>
                  :''} */}
                </td>
                <td style={{ width: '20%', wordBreak: 'break-all'}}>{item.social_media_name}</td>
                <td style={{ width: '20%'}}>{item.social_media_tags}</td>
                <td style={{ width: '20%'}}>
                  {item.social_media_weblink.length>0?
                  <a href={item.social_media_weblink} target="_blank">
                  <FontAwesomeIcon icon={faLink} style={{ fontSize: '14px', color: 'grey' }} />
                  </a>
                  :''}
                </td>
                <td style={{ width: '10%'}}>{item.social_media_date}</td>
                <td style={{ width: '10%'}}>{item.relationship_type}</td>
              </tr>
              {item.social_media_thumbnail?
              <tr>
                <td colSpan="6" >
                {/* {item.social_media_weblink && item.social_media_type===SOCIAL_MEDIA_TYPE.YOUTUBE?
                  <div className="p-3 w-100 text-center">
                    <img style={{ maxHeight: '200px'}} src={`https://img.youtube.com/vi/${this.extractYoutubeId(item)}/0.jpg`}></img>
                  </div>
                  :''} */}
                {item.social_media_type===SOCIAL_MEDIA_TYPE.YOUTUBE && item.extra.thumbnail != null?
                  <div className="w-100 text-center pb-3" style={{ borderBottom: '1px solid #DDDDDD'}}>
                    <div className="p-3 w-75 text-left" style={{ border: '1px solid #AAAAAA', borderRadius: '5px', margin: '0 auto'}}>
                      <div className="d-flex align-items-center pb-3"  style={{ borderBottom: '2px solid #AAAAAA'}}>
                          <img src={youtube} height="20"></img><div className="ml-3"><b>YouTube Video</b></div>
                      </div>
                      <div className="mt-3 d-flex align-items-center">
                        <a href={item.social_media_weblink} target="_blank">
                          <img style={{ maxHeight: '200px'}} src={item.extra.thumbnail}></img>
                        </a>
                        <a href={item.social_media_weblink} target="_blank" style={{ color: 'black'}}>
                          <div className="ml-3">{item.extra.title}</div>                        
                        </a>
                      </div>
                      <div className="mt-3 w-100" style={{ wordBreak: 'break-all'}}>
                        {item.extra.description.length>400?`${item.extra.description.slice(0,400)} ...`:item.extra.description}
                      </div>
                      <div className="mt-1 w-100 text-right" style={{ wordBreak: 'break-all'}}>
                        {item.extra.publishedAt}
                      </div>
                    </div>
                  </div>
                :''}

                {item.social_media_type===SOCIAL_MEDIA_TYPE.TWITTER?
                  <div style={{ borderBottom: '1px solid #DDDDDD'}}>
                    <TweetEmbed className="w-100  d-flex justify-content-center" id={this.extractTwitterId(item)} />
                  </div>
                :''}
                {item.social_media_type===SOCIAL_MEDIA_TYPE.FACEBOOK?
                <div className="w-100  d-flex justify-content-center"  style={{ borderBottom: '1px solid #DDDDDD'}}>
                  <FacebookProvider appId="564870858293382">
                    <EmbeddedPost  href={item.social_media_weblink} width="500" />
                  </FacebookProvider>
                </div>:''}
                {/* {item.social_media_type===SOCIAL_MEDIA_TYPE.OTHER && item.social_media_tags === 'LinkedIn Activity' ? */}
                {item.social_media_type===SOCIAL_MEDIA_TYPE.OTHER ?
                <>
                  <div className="text-center">
                    <iframe src={item.social_media_weblink} 
                      height="300" width="90%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                  </div>
                </>
                :''}
                
                </td>
              </tr>
              :null}
            </React.Fragment>
          )}
        </tbody>
      </table>
      <InspirePagination currentPage={currentPage} 
          totalPage={totalPage} onClick={this.navigatePage.bind(this)}/>
      </>
    )
  }

  onExpandRow(id){
    const { dataTable } = this.state;
    // Keep former value
    let former = dataTable[id].show
    dataTable.map( x => x.show = false)

    // Expand    
    dataTable[id].show = !former;

    // Set State
    this.setState({ dataTable })
  }

  renderTableMobile(){
    const { dataTable } = this.state;
    const { currentPage, totalPage, sorting } = this.state;
    return (<>
      <table className="w-100 inspire-mobile-table">
        <thead>
          <tr>
            {this.dataFieldList.map((item, id) =>
              <td key={id} style={{ cursor: 'pointer' }}
                onClick={(e) => this.onSetSorting(item.dataField)}>
                {item.label}
                <FontAwesomeIcon icon={faLongArrowAltUp} className={sorting == item.dataField ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
                <FontAwesomeIcon icon={faLongArrowAltDown} className={sorting == `-${item.dataField}` ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
              </td>
            )}
          </tr>
          <tr style={{ border: '1px solid #A4C8E6', borderWidth: '1px 0px 2px 0px' }}>
            <td></td>
            {this.dataFieldList.map((item, id) =>
              <td key={id} className="text-center">
                <SearchHeader 
                  onChange={(pattern) => this.retrieveAffiliationsFiltered(item.caption, pattern)} 
                  type={item.type} />
              </td>
            )}
            <td></td>
          </tr>
        </thead>
        <tbody>
          {this.state.isLoading?
            <>
            <tr>
              <td rowSpan="3" style={{ background: 'white', height: '400px' }} colSpan="14" className="text-center">
                <div className="mb-3" style={{ fontSize: '20px', color: 'grey' }} >Loading ...</div>
                <FontAwesomeIcon icon={faSpinner}  spin style={{ fontSize: '40px', color: 'grey' }} />                    
              </td>
            </tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            <tr><td></td></tr>
            </>
            :<tr></tr>}

          {dataTable.map((item, id) =>
            <React.Fragment key={id}>
              <tr>
                <td style={{ width: '40%'}}>{this.getSocialMediaTypeTitle(item.social_media_type)}</td>
                <td style={{ width: '40%'}}>{item.social_media_name}</td>
                <td className={item.enabled?"inspire-table-profile-mobile":'d-none'}>
                  <FontAwesomeIcon icon={faAngleDown} className={item.show ? 'unfolded' : "folded"}
                    style={{ fontSize: '14px', color: 'grey' }}
                    onClick={e => this.onExpandRow(id)} />
                </td>
              </tr>            
              <tr className="inspire-table-subrow">
                <td colSpan="7" className={item.show ? '' : 'd-none'}>                      
                  <AnimateHeight
                    height={item.show ? 'auto': 0}
                    duration={250}>
                    <div className="p-2" style={{ background: '#ECEFF8'}}>
                      <div className="expand-title">TAGS</div>
                      <div className="expand-value">{item.social_media_tags}</div>
                      <div className="expand-title">WEBLINK</div>
                      <div className="expand-value">
                         {item.social_media_weblink&&item.social_media_weblink.length>0?
                          <a href={item.social_media_weblink} target="_blank">
                            <FontAwesomeIcon icon={faLink} style={{ fontSize: '14px', color: 'grey' }} />
                          </a>
                         :''}
                      </div>
                      <div className="expand-title">DATE</div>
                      <div className="expand-value">{item.social_media_date}</div>
                      <div className="expand-title">RELATION TYPE</div>
                      <div className="expand-value">{item.relationship_type}</div>
                    </div>
                  </AnimateHeight>
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
      <InspirePagination currentPage={currentPage} 
          totalPage={totalPage} onClick={this.navigatePage.bind(this)}/>
      </>
    )
  }

  renderDesktop() {

    const { socialMediaType } = this.state;
    const { nYoutube, nTwitter, nFacebook, nOther } = this.state;

    return (
      <>
        <Row>
          <Col xs={{ offset: 1, span: 10 }} className="mb-3">
            <Row>
              <Col sm={{ span: 3 }} className="no-padding">
                  <Affiliation name={"YouTube"} number={nYoutube}
                    img={youtube} active={socialMediaType===SOCIAL_MEDIA_TYPE.YOUTUBE}
                    handler={e => this.showTable(SOCIAL_MEDIA_TYPE.YOUTUBE)} />
              </Col>
              <Col sm={{ span: 3 }} className="no-padding">
                <Affiliation name={"X (Twitter)"} number={nTwitter}
                  img={twitter} active={socialMediaType===SOCIAL_MEDIA_TYPE.TWITTER}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.TWITTER)} />
              </Col>
              <Col sm={{ span: 3 }} className="no-padding">
                <Affiliation name={"Facebook"} number={nFacebook}
                  img={facebook} active={socialMediaType===SOCIAL_MEDIA_TYPE.FACEBOOK}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.FACEBOOK)} />
              </Col>
              <Col sm={{ span: 3 }} className="no-padding">
                <Affiliation name={"Other"} number={nOther}
                  img={linkedin} active={socialMediaType===SOCIAL_MEDIA_TYPE.OTHER}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.OTHER)} />
              </Col>
          </Row>
          </Col>
        </Row>

        <div className="p-3 h-100" style={{ fontSize: '14px'}}>
          {this.renderTableDesktop()}
      </div>
    </>);
  }

  renderMobile() {

    const { socialMediaType } = this.state;
    const { nYoutube, nTwitter, nFacebook, nOther } = this.state;
    const { showTableSideModal, showTableSideTitle } = this.state;

    return (
      <>
        <div>
            <AffiliationMobile name={"YouTube"} number={nYoutube}
                    img={youtube} active={socialMediaType===SOCIAL_MEDIA_TYPE.YOUTUBE}
                    handler={e => this.showTable(SOCIAL_MEDIA_TYPE.YOUTUBE)} />
            <AffiliationMobile name={"Twitter"} number={nTwitter}
                  img={twitter} active={socialMediaType===SOCIAL_MEDIA_TYPE.YOUTUBE}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.TWITTER)} />
            <AffiliationMobile name={"Facebook"} number={nFacebook}
                  img={facebook} active={socialMediaType===SOCIAL_MEDIA_TYPE.FACEBOOK}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.FACEBOOK)} />
            <AffiliationMobile name={"Linkedin"} number={nOther}
                  img={linkedin} active={socialMediaType===SOCIAL_MEDIA_TYPE.OTHER}
                  handler={e => this.showTable(SOCIAL_MEDIA_TYPE.OTHER)} />                  
        </div>

        <div className={showTableSideModal? "inspire-sidemodal-wrapper toggled": "inspire-sidemodal-wrapper"}>
          <div className="p-3">
            <div style={{ fontSize: '20px' }} 
              onClick={(e) => this.setState({ showTableSideModal: false })}>
              <FontAwesomeIcon icon={faAngleLeft}/>              
            </div>
            <div className="mt-3" style={{ fontSize: '20px' }}>
              <b>{showTableSideTitle}</b>  
            </div>
            <div className="mt-3">
              {this.renderTableMobile()}
            </div>
          </div>
        </div>
    </>);
  }

  render() {
    return (window.mobile?this.renderMobile():this.renderDesktop())
  }

  
}

export default withRouter(PanelSocialMedia);
