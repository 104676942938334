import React from 'react';
// Bootstrap
import { Modal, Button, Row, Col } from 'react-bootstrap';

// React Router
import { withRouter } from 'react-router-dom'

// am4charts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faLongArrowAltUp, faLongArrowAltDown, faSpinner, faAngleDown } from '@fortawesome/free-solid-svg-icons'

// EllipsisWithTooltip
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'

// Redux
import { PANEL } from "../../redux";
import { connect } from "react-redux";

// Loading Overlay
import LoadingOverlay from 'react-loading-overlay';

// Styles
import './panelresearchprofile.scss';

// Axios
import axios from 'axios';

// Project imports
import EmptyPanel from '../shared/emptypanel';
import InspirePagination from '../shared/pagination'
import SearchHeader, { SEARCH_HEADER } from '../shared/searchheader';



const mapStateToProps = state => {
  return { 
      tabActive: state.tabActive,
    };
};



const DATA_FIELD_LIST = [
  { 
    dataField:'title', caption: 'title', 
    label: 'Title', type: SEARCH_HEADER.TEXT 
  },
  { 
    dataField:'sjr', caption: 'sjr', 
    label: 'SJR', type: SEARCH_HEADER.NUMBER
  },
  { 
    dataField:'h_index', caption: 'h_index',  
    label: 'H Index', type: SEARCH_HEADER.NUMBER
  },
  { 
    dataField:'total_docs_3_years', caption: 'total_docs_3_years', 
    label: 'Total Docs 3 Years', type: SEARCH_HEADER.NUMBER
  },
  { 
    dataField:'total_refs', caption: 'total_refs', 
    label: 'Total Refs', type: SEARCH_HEADER.NUMBER 
  },
  { 
    dataField:'total_cites_3_years', caption: 'total_cites_3_years', 
    label: 'Total Cites 3 Years', type: SEARCH_HEADER.NUMBER
  },
  { 
    dataField:'country', caption: 'country',  
    label: 'Country', type: SEARCH_HEADER.TEXT
  },
  { 
    dataField:'total_occurences', caption: 'total_occurences',  
    label: 'Total Occurences', type: SEARCH_HEADER.NUMBER
  },
]


class PanelJournal extends React.Component {

  constructor(props) {
    super(props)
    this.dataFieldList = window.mobile?DATA_FIELD_LIST.slice(0,1):DATA_FIELD_LIST;
    const filteringList = this.dataFieldList.reduce((acc,curr)=> (acc[curr.caption]='',acc),{});    
    this.state = {
      isOpened: false,  
      dataTable: [],
      dataChart: [],
      currentPage: 1,
      totalPage: 10,
      take: 10,
      limit: 10,
      isLoading: false,
      filtering : {...filteringList},
      sorting: ''
    }    
  }

  async retrieveJournalList( page = 1 ){
    try{

      this.setState({ isLoading: true, dataTable: [] })
      const token = localStorage.getItem('token')
      const { take, limit, filtering, sorting } = this.state;

      // Perform request
      let skip = this.state.take * (page - 1);
      let offset = this.state.take * (page - 1);
      let urlParams = `limit=${limit}&offset=${offset}&skip=${skip}&take=${take}`


      // Add filtering
      if( filtering !== undefined ){
        for(const item of this.dataFieldList ){
          if( filtering[item.caption] !== '' ){
            urlParams = `${urlParams}&${item.dataField}=${filtering[item.caption]}`;
          }
        }
      }

      // Add sorting
      if( sorting !== ''){
        urlParams = `${urlParams}&ordering=${sorting}`;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/investigator/${this.investigatorId}/journals/?${urlParams}`,
        {
          headers: { "Authorization": "jwt " + token }
        })

      let dataTable = response.data.results
      dataTable = dataTable.filter( x => x.h_index)

      dataTable.map( x => x.show = false)
      dataTable.map( x => x.enabled = true)           
      if(response.data.results.length < take){
        const filteringList = this.dataFieldList.reduce((acc,curr)=> (acc[curr.caption]='',acc),{});    
        const fill = new Array(take - response.data.results.length).fill(filteringList)
        fill.map( x => x.enabled = false)        
        dataTable.push(...fill)
      }

      // Set State
      const totalPage = Math.ceil(response.data.count / take);
      this.setState({
        dataTable: dataTable,
        currentPage: page,
        totalPage: totalPage,
        isLoading: false
      })
    } catch (error) {
      console.log("FAILED")
    }
  }

  async retrieveJournalListChart(){
    try{

      const token = localStorage.getItem('token')

      // Perform request
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/investigator/${this.investigatorId}/journals/?limit=1000`,
        {
          headers: { "Authorization": "jwt " + token }
        })

      let dataChartBubble = response.data.results

      this.setState({
        dataChartBubble: dataChartBubble,
      })
    } catch (error) {
      console.log("FAILED")
    }
  }

  async retrievePublicationsPerJournal(){
    try{

      const token = localStorage.getItem('token')
  
      const { match: { params } } = this.props;
      let investigatorId = params.subid;
      investigatorId = investigatorId.split('-')[investigatorId.split('-').length -1 ]
      investigatorId = parseInt( investigatorId )

      // Perform request
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/investigator/${investigatorId}/publications-per-journal-category/`,
        { headers: { "Authorization": "jwt " + token }
      })
      const journal_data = response.data.results;
      this.setState({dataChart: journal_data})
    }catch(error){
      console.log("FAILED")
    }
  }

  async componentDidMount(){
    try{
      const { match: { params } } = this.props;
      this.investigatorId = params.subid;
      this.investigatorId = this.investigatorId.split('-')[this.investigatorId.split('-').length - 1]
      this.investigatorId = parseInt(this.investigatorId)

      await this.retrieveJournalList()
      await this.retrieveJournalListChart()
      await this.retrievePublicationsPerJournal()
    }catch(error){
      console.log(error.request);
    }
  
  }

  generatePieChart(){


    // No data
    if( this.state.dataChart.length == 0){
      this.setState({isOpened: true})
      return
    }


    const container = "journalpiechart"
    let chart = am4core.create(container, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // Deep copy
    let dataChart = [...this.state.dataChart]
    dataChart = dataChart.sort((a,b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0));
    // Take top 5
    dataChart = dataChart.length>=5?dataChart.slice(0,5):dataChart;

    // Compute percentage
    let totalList = dataChart.map(a => a.total);      
    const sumTotal = totalList.reduce((a, b) => a + b, 0)
    for(const item of dataChart){
      item.percentage = (item.total * 100 / sumTotal).toFixed(0);
    }
    
    chart.data = dataChart;
    chart.innerRadius = am4core.percent(60);

    var series = chart.series.push(new am4charts.PieSeries());
    series.radius = "100%"    
    series.dataFields.value = "percentage";
    // Remove Radius
    // if( window.mobile == false )
    //   series.dataFields.radiusValue = "percentage";
    series.dataFields.category = "journal_category";
    //series.slices.template.cornerRadius = 6;
    series.colors.step = 3;
    // See: https://www.amcharts.com/docs/v4/tutorials/dealing-with-piechart-labels-that-dont-fit/
    series.labels.template.maxWidth = 250;
    series.labels.template.wrap = true;
    series.labels.template.fontSize = 14;

    series.slices.template.tooltipText = "{journal_category} {percentage}% ({total})";

    // series.ticks.template.disabled = true;
    
    // Remove labels
    if( window.mobile )
      series.labels.template.disabled = true;

    series.hiddenState.properties.endAngle = -90;
  
    // Custom colorset for pie series
    const colorSet = new am4core.ColorSet()
    colorSet.list = [
      // Row1
      am4core.color("#A4C8E6"),
      am4core.color("#4A92CE"),
      am4core.color("#80B2DC"),
      am4core.color("#336690"),
      am4core.color("#162B3D"),      
    ].map(function(color) {
      return new am4core.color(color);
    });    
    series.colors = colorSet 

    //chart.legend = new am4charts.Legend();
    this.pieChart = chart
    this.pieChart.legend = new am4charts.Legend();
    this.pieChart.legend.fontSize = 12;    
    
    if( window.mobile ){
      this.pieChart.legend.position = "bottom"
    }else{
      this.pieChart.legend.position = "right"
    }
    

    // Set state after timeout
    this.setState({isOpened: true})
  }

  generateBubbleChart(){
    const { dataChartBubble } = this.state;

    // Create chart instance
    var chart = am4core.create("journalbubblechart", am4charts.XYChart);
    chart.colors.step = 3;


    // Add chart data
    const chartData = []
    for(const item of dataChartBubble){
      if( item.total_refs )
        chartData.push({x: item.h_index, y: item.sjr, value: item.total_refs, ...item})
    }
    chart.data = chartData;

    // Create axes
    var xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.renderer.minGridDistance = 50;
    xAxis.title.text = "H Index";

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.minGridDistance = 50;
    yAxis.title.text = "SJR";

    // Create series #1
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    
    series.dataFields.value = "value";
    series.strokeOpacity = 0;

    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.strokeOpacity = 0.2;
    bullet.stroke = am4core.color("#ffffff");
    //bullet.circle.fill = am4core.color(color);

    bullet.nonScalingStroke = true;
    bullet.tooltipHTML = `<div className="text-left">    
                            <div>Title: {title}</div>
                            <div>H Index: {h_index}</div>    
                            <div>SJR: {sjr}</div>
                            <div>Total Ref: {total_refs}</div>
                            <div>Country: {country}</div>
                          </div>`
    series.heatRules.push({
      target: bullet.circle,
      min: 10,
      max: 60,
      property: "radius"
    })
    series.heatRules.push({
      target: bullet.circle,
      min: am4core.color("#A4C8E6"),
      max: am4core.color("#162B3D"),
      property: "fill"
    });

    //chart.legend = new am4charts.Legend();
    this.bubbleChart = chart;
  }

  generateChart(){
    this.generatePieChart()
    this.generateBubbleChart()
  }

  componentWillUnmount() {
    if (this.pieChart)
      this.pieChart.dispose();    
    if (this.bubbleChart)
      this.bubbleChart.dispose();
    
  }

  generateTable() {
    const { dataTable, totalPage, currentPage, sorting } = this.state;
    return (
      <div className="p-3 h-100" style={{ fontSize: '14px' }}>
          <table className="inspire-table w-100" style={{ fontSize: '13px' }}>
            <thead>
              <tr>                
                {this.dataFieldList.map((item, id) =>
                      <td key={id} className="text-center" style={{ cursor: 'pointer' }}
                      onClick={(e) => this.onSetSorting(item.dataField)}>
                      {item.label}
                      <FontAwesomeIcon icon={faLongArrowAltUp} className={sorting == item.dataField ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
                      <FontAwesomeIcon icon={faLongArrowAltDown} className={sorting == `-${item.dataField}` ? "ml-1" : "ml-1 d-none"} style={{ color: 'grey' }} />
                    </td>                )}
              </tr>
              <tr style={{ border: '1px solid #A4C8E6', borderWidth: '1px 0px 2px 0px' }}>
                {this.dataFieldList.map((item, id) =>
                <td key={id} className="text-center" >
                  <SearchHeader 
                    onChange={(pattern) => this.retrieveJournalListFiltered(item.caption, pattern)} 
                    type={item.type} />
                </td>
              )}
              </tr>
            </thead>
            <tbody>
              {this.state.isLoading?
                    <>
                    <tr>
                      <td></td>
                      <td rowSpan="10" style={{ background: 'white', height: '400px' }} colSpan="14" className="text-center">
                        <div className="mb-3" style={{ fontSize: '20px', color: 'grey' }} >Loading ...</div>
                        <FontAwesomeIcon icon={faSpinner}  spin style={{ fontSize: '40px', color: 'grey' }} />                    
                      </td>
                    </tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    </>
                    :<tr></tr>}
              {dataTable.map((item, id) =>
                <tr key={id}>
                  <td  className="text-left" >
                    <EllipsisWithTooltip placement="bottom" style={{ width: '300px'}}>
                    {item.title || ''}
                    </EllipsisWithTooltip>
                  </td>
                  <td  className="text-center" >{item.sjr}</td>
                  <td  className="text-center" >{item.h_index}</td>
                  <td  className="text-center" >{item.total_docs_3_years}</td>
                  <td  className="text-center" style={{ width: '10%' }}>{item.total_refs}</td>
                  <td  className="text-center" style={{ width: '10%' }}>{item.total_cites_3_years}</td>
                  <td  className="text-center" style={{ width: '10%' }}>{item.country}</td>
                  <td  className="text-center" style={{ width: '10%' }}>{item.total_occurrences}</td>
                </tr>
              )}
            </tbody>
          </table>
        <InspirePagination currentPage={currentPage} totalPage={totalPage} onClick={this.navigatePage.bind(this)} />
      </div>)
  }

  onSetSorting(field){
    let { currentPage, sorting } = this.state;
    let target = '';
    if( sorting == '' || sorting.includes(field) == false){
      target = field
    }else if( sorting === field ){
     target =  `-${field}`
    }
    this.state.sorting = target;

    this.retrieveJournalList(currentPage)
  }

  navigatePage(page) {
    this.retrieveJournalList(page)
  }

  retrieveJournalListFiltered(key, value) {
    let { currentPage, filtering } = this.state;
    for(const item_canidate of this.dataFieldList ){
      if( key === item_canidate.caption ){
        filtering[item_canidate.caption] = value
      }
    }

    this.state.filtering = filtering
    // Clear timeout
    const that = this;
    if ( this.typingTimeout ) {
      clearTimeout(this.typingTimeout);
    }
    this.typingTimeout = 
      setTimeout(function () { that.retrieveJournalList(currentPage) }, 2000)
  }

  renderDesktop() {

    if( this.props.tabActive == PANEL.JOURNAL && 
        this.state.isOpened == false &&
        this.state.dataChart !== undefined){
      const that = this;
      setTimeout(function(){ that.generateChart() }, 500);
    }

    const emptyPanelShow = this.state.dataChart.length == 0 
                            && this.props.tabActive == PANEL.JOURNAL;
    return (
      <div>    
        <LoadingOverlay
          active={this.state.isOpened == false}
          spinner>            
          <EmptyPanel show={emptyPanelShow} />
          {!emptyPanelShow?
            <>
              <div>Journal Main Focus</div>
              <div id="journalpiechart" style={{ width: '100%', height: '300px', marginTop: '20px' }}></div>

              <div className="mt-3">Journal Distribution </div>
              <small>
                <svg height="20" width="20">
                <circle cx="10" cy="10" r="5" stroke="black" strokeWidth="0" fill="#A4C8E6" />
                </svg>
                <i>
                Bubble size/color represents the Total Ref</i>
              </small>
              
              <div id="journalbubblechart" style={{ width: '100%', height: '300px', marginTop: '20px' }}></div>
            </> 
            :''}
          <div className="mt-3">{this.generateTable()}</div>


        </LoadingOverlay> 


      </div>);
  }

  renderMobile(){    
    if( this.props.tabActive == PANEL.JOURNAL && 
      this.state.isOpened == false &&
      this.state.dataChart !== undefined){
      const that = this;
      setTimeout(function(){ that.generateChart() }, 500);
    }

    const emptyPanelShow = this.state.dataChart.length == 0 
                            && this.props.tabActive == PANEL.JOURNAL;

    return (
      <>
        <LoadingOverlay
          active={this.state.isOpened == false}
          spinner>

          <EmptyPanel show={emptyPanelShow} />
          {!emptyPanelShow ?
            <>
              <div className="ml-3">Journal Main Focus</div>
              <div id="journalpiechart" style={{ width: '100%', height: '300px', marginTop: '20px' }}></div>

              <div className="mt-3 ml-3">Jounal Distribution</div>
              <div id="journalbubblechart" style={{ width: '100%', height: '300px', marginTop: '20px' }}></div>
            </>
            : ''}
        </LoadingOverlay>
      </>
    )
  }

  render(){
    return (window.mobile?this.renderMobile():this.renderDesktop())
  }
}

export default connect(mapStateToProps, undefined)(withRouter(PanelJournal))